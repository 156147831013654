import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import './App.css';
import './styles/main.scss';
import MainPage from './pages/MainPage';


function App() {

  return (
      <div className="App">
        <Router>
         <MainPage />
        </Router>
      </div>
  );
}

export default App;

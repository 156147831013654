import Analytics from 'analytics';
import hubSpotPlugin from '@analytics/hubspot';

const utils = {

  getHTML: (fieldObj) => {
    return { __html: fieldObj }
  },

  getIndexOfValueInArrayOfObjectsByProp: function (propName, value, listToCheck) {
    const lenListToCheck = listToCheck.length;

    if (lenListToCheck < 1) {
      return undefined;
    }

    for (let i = 0; i < lenListToCheck; i++) {
      if (listToCheck[i][propName] === value) {
        return i;
      }
    }

    //iterated through the list and didn't see it
    return undefined;
  },

  getPageOfResults: function( originalList, pageNumber, numberInPage ) {
    let startOfSlice = (pageNumber-1) * numberInPage;
    let endOfSlice = startOfSlice + numberInPage;
    let slicedList = originalList.slice(startOfSlice, endOfSlice);
    return slicedList;
},

// HubSpot analytics/code tracking
initializeTracking: function(){
  let hsPlugin = {portalId: '8615684'};
  
  const analytics = Analytics({
      app: 'Linksbridge Platform',
      plugins: [
        hubSpotPlugin(hsPlugin)
      ]
    });

    // Track a page view 
    analytics.page()
},

}

export default utils;
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Pagination from 'rc-pagination';

import CEFilterBar from './CEFilterBar';
import utils from '../utils';
import NavBar from './NavBar';

function HomePage({ countryList, diseaseList, fullNewsList, filterChanged, filters }) {

  const [curPage, setCurPage] = useState(1);
  const NUMBER_PER_PAGE = 5;

  //hubspot analytics
  useEffect(() => {
    utils.initializeTracking();
  });

  const handlePageClick = (num) => {
    setCurPage(num);
  }

  return (
    <>
      <NavBar />
      <Row className='full-news-page'>
        <Col className='filter-sideNav' sm={12} md={3}>
          <CEFilterBar
            countryList={countryList}
            diseaseList={diseaseList}
            onFilterChanged={filterChanged}
            curFilters={filters}
          />
        </Col>
        <Col sm={12} md={9}>
          {
            fullNewsList && fullNewsList.length > 0 ?
              <>
                {
                  utils.getPageOfResults(fullNewsList, curPage, NUMBER_PER_PAGE).map((thisItem, idx) => {
                    return (
                      <Link className='news-article' key={idx} to={`articles/${thisItem.id}`}>
                        <h5 className='article-title'>{thisItem.title.rendered}</h5>
                        <p className='article-teaser' dangerouslySetInnerHTML={utils.getHTML(thisItem.content.rendered)}></p>
                      </Link>
                    )
                  })
                }
                <div className="text-center">
                  <Pagination
                    total={fullNewsList.length}
                    onChange={(item) => handlePageClick(item)}
                    current={curPage}
                  />
                </div>
              </>
              :
              <div className="text-center">No Relevant Results Returned</div>
          }
        </Col>
      </Row>
    </>
  )
}

export default HomePage;
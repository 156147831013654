import React, { useState, useEffect } from 'react';
import { Card, Spinner, Nav, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CalendarFill, TagFill } from 'react-bootstrap-icons';

import utils from '../utils';
import moment from 'moment';

function SingleArticlePage() {

  let { articleID } = useParams();

  const [loading, setLoading] = useState(true);
  const [article, setArticle] = useState();

  //hubspot analytics
  useEffect( () => {
    utils.initializeTracking();
  });

  //Gets single article and saves in state
  const getArticle = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_CE_WP_URL}posts/${articleID}`, {
      });
      const data = await res.json();
      setArticle(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    getArticle();
    setLoading(false)
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Nav className='article-ce-nav'>
        <Nav.Item>
          <Nav.Link href="/">Campaign News</Nav.Link>
        </Nav.Item>
      </Nav>
      <div className='single-article-page'>
        {
          !loading && article ?
            <div>
              <Card>
                <Card.Body>
                  <Card.Title>{article.title.rendered}</Card.Title>
                  <Row className='sub-title'>
                    <Col xs={12} sm={4} className='calendar-col'>
                      <CalendarFill className='calendar-svg' />
                      {moment(article.date).format('LL')}
                    </Col>
                    <Col xs={12} sm={8} className='tag-col'>
                      <TagFill className='tag-svg' />
                      Featured Post
                    </Col>
                  </Row>
                  <Card.Text className='full-article' dangerouslySetInnerHTML={utils.getHTML(article.content.rendered)}>
                  </Card.Text>

                </Card.Body>
              </Card>
            </div>
            :
            <Spinner animation='border' />
        }
      </div>
    </>
  )
}

export default SingleArticlePage;
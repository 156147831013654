import React, { useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import HomePage from '../components/HomePage';
import SingleArticlePage from '../components/SingleArticlePage';

function MainPage() {

  let { path } = useRouteMatch();
  let countryAPI = 'post_region_country?categories=245&';
  let diseaseAPI = 'post_health_domain?categories=245&';
  let newsAPI = 'posts?categories=245&';

  const [countryList, setCountryList] = useState();
  const [diseaseList, setDiseaseList] = useState();
  const [fullNewsList, setFullNewsList] = useState();
  const [filters, setFilters] = useState([]);
  const [loading, setLoading] = useState(true);

  // Endpoint call to get data based on API or params
  const getData = async (filters, api) => {
    let pageNo = 1;
    let params = '';

    if (filters && filters.length > 0) {
      filters.forEach((item) => {
        if (params) {
          params = `${params}${item.name}=${item.value}&`;
        } else {
          params = `${item.name}=${item.value}&`;
        }
      })
      params = `${params}per_page=100&page=${pageNo}`
    } else {
      params = `per_page=100&page=${pageNo}`
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_CE_WP_URL}${api}${params}`, {
      });
      let jsonResponse = await res.json();
      setDataInState(jsonResponse, api);
    } catch (err) {
      console.log(err);
    }
  }

  //sets data in state
  const setDataInState = (list, api) => {

    if (api === 'post_region_country?categories=245&') {
      setCountryList(list);
    }

    if (api === 'post_health_domain?categories=245&') {
      setDiseaseList(list);
    }

    if (api === 'posts?categories=245&') {
      setFullNewsList(list);
    }
    setLoading(false)
  }

  useEffect(() => {
    if (filters.length > 1) {
      setLoading(true);
      getData(filters, newsAPI);
      setLoading(false);
    }
    setLoading(true);
    getData(filters, countryAPI);
    getData(filters, diseaseAPI);
    getData(filters, newsAPI);
    setLoading(false);

    // eslint-disable-next-line
  }, [countryAPI, diseaseAPI, newsAPI, filters]);

  //Looks for filter in filter array and removes filter
  const removeFilter = (filterList, filterType) => {

    for(let i =0; i < filterList.length; i++) {
      if (filterList[i].name === filterType) {
        filterList.splice(i, 1);
        return filterList;
      }
    }
    return filterList;
  }

  const filterOff = (filterList, filterType) => {
    const index = filterList.findIndex((item) => {
    return item.name === filterType;
    })
    filterList.splice(index, 1)
    return filterList
  }

  // Adds new filter
  const addNewFilter = (filterList, newFilter) => {
    filterList.push(newFilter);
    return filterList;
  }

  //Adds and removes filter based on filter selected and sets in state
  const filterChanged = (newFilter) => {

    let tempState = [...filters];
    
    if (newFilter.name === 'post_region_country') {
      tempState = removeFilter(tempState, 'post_region_country')
      tempState = addNewFilter(tempState, newFilter)
    }

    if (newFilter.name === 'post_health_domain') {
      tempState = removeFilter(tempState, 'post_health_domain')
      tempState = addNewFilter(tempState, newFilter)
    }

    if (newFilter === 'clear-filter-country') {
      tempState = filterOff(tempState, 'post_region_country')
    }

    if (newFilter === 'clear-filter-disease') {
      tempState = filterOff(tempState, 'post_health_domain')
    }

    if (newFilter.name === 'tags') {

      if (newFilter.checked === false) {
        tempState = filterOff(tempState, newFilter.name)
      } else {
        tempState = removeFilter(tempState, 'tags_exclude')
        tempState = addNewFilter(tempState, newFilter)
      }
    }

    if (newFilter.name === 'tags_exclude') {
      
      if (newFilter.checked === false) {
        tempState = filterOff(tempState, newFilter.name)
      } else {
        tempState = removeFilter(tempState, 'tags')
        tempState = addNewFilter(tempState, newFilter)
      }
    }

    setFilters(tempState)
  }

  return (
    !loading && countryList && diseaseList && fullNewsList ?
      <div>
        <Switch>
          <Route exact path={`/`}>
            <HomePage
              countryList={countryList}
              diseaseList={diseaseList}
              filterChanged={filterChanged}
              filters={filters}
              fullNewsList={fullNewsList}
            />
          </Route>
          <Route path={`${path}articles/:articleID`}>
            <SingleArticlePage
              countryList={countryList}
            />
          </Route>
        </Switch>
      </div>
      :
      <Spinner annimation='border' />
  )
}

export default MainPage;
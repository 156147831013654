import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

function DiseaseFilter({ diseaseList, filterChangeHandler, curFilter }) {

  const handleSelect = (key) => {
    //when disease is selected find the key that matches in the diseaseList and set in state
    if (key === 'clear-filter-disease') {
      filterChangeHandler(key)
    } else {

      let foundArr = diseaseList.filter((item) => {
        return item.name.toLowerCase() === key.toLowerCase();
      });

      // Creates obj to send to the change handler function
      filterChangeHandler({
        name: 'post_health_domain',
        label: foundArr[0].name,
        value: foundArr[0].id
      });
    }
  };

  // Finds and returns the correct label to display in dropdown titles based on filter selected
  const findFilterTitle = (filters) => {
    let labelName = 'Disease';

    if (filters) {
      filters.forEach((item) => {
        if (item.name === 'post_health_domain') {
          return labelName = item.label;
        }
      });
    }
    return labelName;
  };

  return (
    <DropdownButton
      className='filter-dropdown'
      id="disease-filter"
      title={findFilterTitle(curFilter)}
      onSelect={(eventKey) => handleSelect(eventKey)} >
      <Dropdown.Item
        eventKey={'clear-filter-disease'}
      >
        Select All
      </Dropdown.Item>
      {
        diseaseList.map((disease, idx) => {
          return (
            <Dropdown.Item
              key={idx}
              eventKey={disease.name}>
              {disease.name}
            </Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}

export default DiseaseFilter
import React from 'react';
import { Navbar, Container, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function NavBar() {
  return (
    <div>
      <Navbar className='ce-nav'>
        <Container fluid>
          <Row>
            <Col sm={8} md={6} lg={4} className='header-col'>
              <h3>Campaign News</h3>
            </Col>
            <Col sm={4} md={6} lg={8} className='subscribe-col'>
              <a href='https://share.hsforms.com/1PL1zDURDTViPLlDIcqvz3w54nwk' target='_blank' rel='noreferrer'>
              <Button>Subscribe</Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Navbar>
    </div>
  )
}

export default NavBar;
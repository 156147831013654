import React from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';

function CountryFilter({ countryList, filterChangeHandler, curFilter }) {

  const handleSelect = (key) => {
    //when country is selected find the key that matches in the countryList and set in state
    if (key === 'clear-filter-country') {
      filterChangeHandler(key);
    } else {
      let foundArr = countryList.filter((item) => {
        return item.name.toLowerCase() === key.toLowerCase();
      })

      // Creates obj to send to the change handler function
      filterChangeHandler({
        name: 'post_region_country',
        label: foundArr[0].name,
        value: foundArr[0].id
      });
    }
  }

  const findFilterTitle = (filters) => {
    let labelName = 'Country';

    if (filters) {
      filters.forEach((item) => {
        if (item.name === 'post_region_country') {
          return labelName = item.label;
        }
      })
    }
    return labelName;
  }

  return (
    <DropdownButton
      className='filter-dropdown'
      id="country-filter"
      title={findFilterTitle(curFilter)}
      onSelect={(eventKey) => handleSelect(eventKey)} >
      <Dropdown.Item
        eventKey={'clear-filter-country'}
      >
        Select All
      </Dropdown.Item>
      {
        countryList.map((country, idx) => {
          return (
            <Dropdown.Item
              key={idx}
              eventKey={country.name}>
              {country.name}
            </Dropdown.Item>
          )
        })
      }
    </DropdownButton>
  )
}

export default CountryFilter